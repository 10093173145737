import React from "react";
import { ProductLogo } from "../../../../../_metronic/layout/components/productLogo";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { displayPrice, getProductUrl, formatLength, displayPrices } from "../../../../helpers";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";

export const ProductWidgetComponent = (props) => {
    const { product, author } = props,
        { t } = useTranslation();

    let episodesCount = product.episodes.length,
        totalDuration = formatLength(product.totalDuration);

    const statisticsJSX = <div className="statistics">
        {
            <div className="statistics-item">
                <SVG
                    src={toAbsoluteUrl(
                        "/media/def-image/author-page/product/episodesIcon.svg"
                    )}
                />
                <span className="statistics-item-title">{episodesCount} {t("episodes")}</span>
            </div>
        }
        {
            totalDuration && totalDuration != "-:--" ? (
                <div className="statistics-item">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/def-image/author-page/product/durationIcon.svg"
                        )}
                    />
                    <span className="statistics-item-title">{totalDuration}</span>
                </div>
            ) : <></>
        }
    </div>;

    return <div className="col-12 col-md-6 col-lg-4">
        <div className="product">
            <a href={getProductUrl(product)}>
                {(product.unlisted || product.draft || !product.salesPageLive || author.planFeatures.salesPage) && (
                    <span className="badge gray-badge unlisted-badge">Unlisted</span>
                )}
                <ProductLogo product={product} className="icon" />
                <span className="title">{product.name}</span>
                {statisticsJSX}
                <span className="description">{product.description}</span>
                <PriceRange product={product} />
            </a>
        </div>
    </div>;
};

export function Price({ price }) {
    return (
        <>
            {price.free
                ? price.value
                : price.currency + price.value}
        </>
    );
}

export function PriceRange({ product }) {
    let { t, i18n } = useTranslation();

    let range = displayPrices(product, {
        range: true,
        t
    });

    if(!range)
        return <></>;

    if(range.length == 1)
        return (
            <span className="price">
                <Price price={range[0]} />
            </span>
        );

    return (
        <span className="price">
            <Price price={range[0]} />
            <> - </>
            <Price price={range[range.length - 1]} />
        </span>
    );
}
