import { useFetch } from "../../app/helpers";

export default {
  getProducts: hideError => useFetch.get('products', {}, hideError),
  createProduct: data => useFetch.post('product', data),
  editProduct: data => useFetch.put('product', data),
  editSalesPage: (id, data) => useFetch.put("sales-page/" + id, data),
  getProduct: id => useFetch.get(`product/${id}`, {}),
  deleteProduct: id => useFetch.delete(`product/${id}`, {}),
  connectYoutube: accessToken => useFetch.post("connect-youtube", { accessToken }),
  importChannel: (accessToken, id) => useFetch.post("import-youtube", { accessToken, id }),
  tryImportRss: url => useFetch.post("import-rss", { try: url }),
  importRss: url => useFetch.post("import-rss", { url }),
  resendImportEmail: id => useFetch.get("resend-import-rss/" + id),
  getToInstall: (id, key) => useFetch.get("app/product-to-install/" + id + "/" + key, null, true, true),
  createCategory: data => useFetch.post("/categories", { data }, true, true),
  updateCategory: (id, data) => useFetch.put("/categories/" + id, { data }, true, true),
  deleteCategory: id => useFetch.delete("/categories/" + id, null, true, true),
  createPrice: (id, data) => useFetch.post("/products/" + id + "/prices", data, true, true),
  updatePrice: (productId, priceId, data) => useFetch.put("/products/" + productId + "/prices/" + priceId, data, true, true),
  deletePrice: (productId, priceId) => useFetch.delete("/products/" + productId + "/prices/" + priceId, null, true, true),
};
