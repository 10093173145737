import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Steps from "./generic/Steps";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Formik, Form } from "formik";
import { Persist } from 'formik-persist';
import api from "../../../../redux/api";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import schemas from "../../../schemas";
import { useMediaQuery } from "react-responsive";
import { checkLimits } from "../../../plans";
import BulkUploadDropzone from "../episodes/generic/BulkUploadDropzone";
import { toastMessage } from "../../../helpers";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import UnsavedFormGuard from "../../../layout/components/UnsavedFormGuard";

let newEpisodeTemplate = {
  title: "",
  summary: "",
  audioFileName: null,
  originalFileUrl: null,
  audioUrl: "",
  durationInSeconds: null,
  audioFileSizeInBytes: null,
  delayAfterPreviousInDays: 1,
  releaseDate: new Date(),
  draft: 1,
  dripReleaseNumber: 1,
  dripReleasePeriod: "days",
  expireAfterNumber: 1,
  expireAfterPeriod: "days",
  available: false,
  disableNewEpisodeNotifications: false
};

function CreateProduct({
  setListeners,
  dispatch,
  listeners,
  setProducts,
  products,
  fulfillUser,
  user
}) {
  const history = useHistory(),
    [showStep, setShowStep] = React.useState(1),
    [saving, setSaving] = useState(false),
    [idProduct, setIdProduct] = React.useState(null),
    [stepMode, setStepMode] = React.useState("steps"),
    [redirectToProduct, setRedirectToProduct] = React.useState(false),
    [validFirst, setValidFirst] = React.useState(false),
    [episodesCount, setEpisodesCount] = React.useState(0),
    [episodesCountDetails, setEpisodesCountDetails] = React.useState(0),
    [uploading, setUploading] = useState(null),
    [uploadedFiles, setUploadedFiles] = useState([]),
    [creatingEpisodes, setCreatingEpisodes] = useState(false),
    [showEpisodesUnsaved, setShowEpisodesUnsaved] = useState(false),
    formikRef = useRef();

  // [totalProducts, setProducts] = React.useState("loading");

  useEffect(() => {
    dispatch(setListeners([]));
    if(user)
      checkLimits.canAddProduct(user);
  }, []);

  //useEffect(() => {
  //}, [ user ]);

  const getListeners = (productId) =>
    api.listener.getListeners(productId).then((res) => {
      dispatch(setListeners(res));
    });

  const getProducts = () =>
    api.product.getProducts().then((res) => {
      dispatch(setProducts(res));
    });

  const getUser = () =>
    api.auth.getUserByToken(true).then(res => {
      dispatch(fulfillUser(res.data));
    });

  const sendFormPublish = (formik, { draft, redirectToProduct }) => {
    formik.setFieldValue("draft", draft);
    setRedirectToProduct(redirectToProduct);
    formik.submitForm();
    clearPersisted();
  };

  const sendForm = async (product, saving = null) => {
    setSaving(saving || (product.draft ? "draft" : "publish"));

    if(idProduct) {
      await api.product
        .editProduct({
          product: {
            ...product,
            _id: idProduct,
            sendAccess: !product.draft,
          },
        });

      setSaving(false);
      clearPersisted();

      if(redirectToProduct) {
        history.push(`/products/${idProduct}`, { ignorePrompt: true });
      } else {
        getListeners(idProduct);
      }
    } else {
      //console.log('send');
      let res = await api.product
        .createProduct({
          product: {
            ...product
          },
        });

      clearPersisted();
      setSaving(false);

      //console.log('res', res);
      if(res.storedObject) {
        setIdProduct(res.storedObject._id);
        getListeners(res.storedObject._id);
        getProducts();
        getUser();
      }
    }
  };

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  /**
   * Clears the persisted state of the form fields.
   */
  const clearPersisted = function() {
    //TODO This is tied too tightly to formik-persist inner workings, but it lacks methods for this, we may want to consider creating our own component for this behaviour in the future
    window.localStorage.removeItem("product-form");
  };

  /**
   * Executes the form's validation.
   * @param {*} values 
   * @param {*} props 
   */
  const validateForm = (values, props) => {
    try {
      schemas.product.product.full.validateSync(values, {
        context: {},
        abortEarly: false
      });
    } catch(exception) {
      if(exception.inner) {
        return {
          ...exception.inner.reduce((obj, error) => ({
            ...obj,
            [error.path]: error.message
          }), {})
        };
      }
    }
  };

  let closeModal = (validate = true) => {
    if(validate && (uploadedFiles.length || uploading))
      return setShowEpisodesUnsaved(true);

    clearPersisted();
    history.push("/products/" + idProduct + "/episodes", { ignorePrompt: true });
  };

  let uploadEpisodes = async (loading = true) => {
    //TODO Code copied from BulkUpload.js; Finish cleanup, assign properties on the backend.

    if(!uploadedFiles.length) return;

    setCreatingEpisodes(loading);

    let episodesData = [];
    uploadedFiles.forEach((file, index) => {
      if(file.file && file.amazonS3Url) {
        let episode = _.clone(newEpisodeTemplate);

        episode.title = file.file.name;
        episode.audioFileName = file.file.name;
        episode.audioFileSizeInBytes = file.file.size;
        episode.originalFileUrl = file.amazonS3Url;
        episode.durationInSeconds = file.durationInSeconds;

        episodesData.push(episode);
      }
    });

    let res = await api.episode.createEpisodesBulk({
      id: idProduct,
      data: { episodes: episodesData },
    });

    setShowEpisodesUnsaved(false);

    if(res.success) {
      toastMessage.success("Episodes created successfully.");
      closeModal(false);
    }
  };

  let episodesUnsavedOnSave = async () => {
    await uploadEpisodes("modal");
  };

  let episodesUnsavedOnCancel = async () => {
    closeModal(false);
  };

  let episodesUnsavedOnHide = () =>
    setShowEpisodesUnsaved(false);

  const podcastCreatedModalJSX = idProduct && (
    <>
      <Modal
        show={true}
        onHide={() => closeModal()}
        size="lg"
        centered
        className="podcast-created-modal text-center"
      >
        <Modal.Body className="p-15">
          <button type="button" className="close" onClick={() => closeModal()}><SVG src={toAbsoluteUrl("/media/def-image/close.svg")} className="svg-icon" /></button>

          <img src={toAbsoluteUrl("/media/def-image/icons/clapping-hands-modal.svg")} className="podcast-created-modal-icon" />
          <h1>Congrats, your product was created!</h1>
          <p className="text-gray">Let’s keep going and upload your episodes.</p>

          <BulkUploadDropzone uploadStarted={() => setUploading(true)} uploadComplete={() => setUploading(false)} setFileList={list => setUploadedFiles(list)} />

          {uploading === null
            ? <button type="button" className="btn btn-secondary" onClick={() => closeModal(false)}>I’ll do this later</button>
            : <button type="button" className={"btn btn-primary " + (creatingEpisodes === true ? "loading spinner" : "")} disabled={uploading} onClick={() => uploadEpisodes()}>{uploading ? "Uploading, please wait..." : "Create Episodes"}</button>}
        </Modal.Body>
      </Modal>
      <UnsavedFormGuard dirty={uploadedFiles.length || uploading} valid onSaveAsync={episodesUnsavedOnSave} onCancelAsync={episodesUnsavedOnCancel} onHide={episodesUnsavedOnHide} loading={creatingEpisodes == "modal"} showDialog={showEpisodesUnsaved} />
    </>);

  let save = async () => {
    await sendForm(formikRef.current.values, "modal");
  };

  return (
    <>
      <h1>
        <Link to="/products" className="btn btn-back">
          <SVG src={toAbsoluteUrl("/media/def-image/icons/back.svg")} />
        </Link>
        New Show
      </h1>

      <div className="custom-page create-product-page">
        <Formik
          initialValues={{
            draft: 0,
            name: "",
            description: "",
            email: "",
            releaseMode: 0,
            livePeriod: 0,
            allowDownloads: 0,
            fromName: "",
            replyToEmail: "",
            emailContent: "",
            emailSubject: "",
            instructorName: "",
            productExpires: 1,
            productExpiresMode: "days",
            displayCountdown: false,
            appOnly: false,
            artwork: {
              url: '',
              color: 'color-9',
              icon: 'icon-1',
            },
            color: {
              code: "",
              dark: true
            },
            sendEmailFromId: user.emailAddresses.find(email => email.default)?._id,
            enableSampleEpisodes: false,
            unlockLink: "sales-page",
            unlockUrl: "",
            promote: true,
            promoteRestrictByCategory: false
          }}
          validate={validateForm}
          onSubmit={values => sendForm(values)}
          innerRef={formikRef}
        >
          {(formik) => (
            <>
              <Form className="form fv-plugins-bootstrap fv-plugins-framework">
                {podcastCreatedModalJSX}
                <div className="">
                  <Steps
                    formik={formik}
                    showStep={showStep}
                    isMobile={isMobile}
                    setValidFirst={setValidFirst}
                    saving={saving}
                    history={history}
                  />
                </div>
                <Persist name="product-form" />
              </Form>
            </>
          )}
        </Formik>
      </div>

      {!idProduct && <UnsavedFormGuard formikRef={formikRef} onSaveAsync={save} loading={saving == "modal"} />}
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      episodes: (state.product.product || {}).episodes || [],
      listeners: (state.listener || {}).listeners || [],
      user: state.auth.user
    }),

    (dispatch) => ({
      ...actions.product,
      ...actions.listener,
      ...actions.auth,
      dispatch,
    })
  )(CreateProduct)
);
