export default {
    translation: {
        "/ wk": "/ Wo",
        "/ mo": "/ Mo",
        "/ yr": "/ J",
        "Click for Access": "Klicken für Zugang",
        "Buy Now": "Jetzt kaufen",
        "Select plan": "Plan auswählen",
        "{{episodes}} episodes - free": "{{episodes}} Episoden - kostenlos",
        "{{episodes}} episodes - {{displayPrice}}": "{{episodes}} Episoden - {{displayPrice}}",
        "All": "Alle",
        "Audio Feeds": "Audio-Feeds",
        "Category": "Kategorie",
        "There are no shows in this category.": "In dieser Kategorie gibt es keine Sendungen.",
        "The creator doesn’t have any public audio feeds yet.": "Der Ersteller hat noch keine öffentlichen Audio-Feeds.",
        "About me": "Über mich",
        "My audio feeds": "Meine Audio-Feeds",
        "I agree to Hiro’s <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">terms of service</a> & <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">privacy policy</a>.": "Ich stimme den Nutzungsbedingungen und Datenschutzrichtlinien von Hiro zu.",
        "Powered by Hiro.fm": "Powered by Hiro.fm",
        "Start your own private audio product": "Starten Sie Ihr eigenes privates Audio-Produkt",
        "episodes": "Episoden",
        "Free": "Kostenlos",
        "/ week": "/ Woche",
        "/ month": "/ Monat",
        "/ year": "/ Jahr",
        "one-time payment": "Einmalige Zahlung",
        "per week": "pro Woche",
        "per month": "pro Monat",
        "per year": "pro Jahr",
        "Products": "Produkte",
        "Episodes": "Episoden",
        "Listeners": "Zuhörer",
        "Downloads": "Downloads",
        "My activity": "Meine Aktivitäten",
        "Created by:": "Erstellt von:",
        "Last update:": "Letztes Update:",
        "This audio feed doesn’t have any eposides yet.": "Dieser Audio-Feed hat noch keine Episoden.",
        "See more": "Mehr sehen",
        "Please verify the credit card details.": "Bitte überprüfen Sie die Kreditkartendetails.",
        "Payment": "Zahlung",
        "Your details": "Ihre Daten",
        "First Name": "Vorname",
        "Last Name": "Nachname",
        "Email": "E-Mail",
        "Phone (optional)": "Telefon (optional)",
        "Invalid phone number.": "Ungültige Telefonnummer.",
        "Payment details": "Zahlungsdetails",
        "Total payment": "Gesamtzahlung",
        "Get Access": "Zugang erhalten",
        "Pay": "Bezahlen",
        "Category:": "Kategorie:",
        "Get Free Access": "Kostenlosen Zugang erhalten",
        "Show currently unavailable": "Zurzeit nicht verfügbar",
        "Buy now for {{priceValue}}": "Jetzt kaufen für {{priceValue}}",
        "Length": "Länge",
        "reviews": "Bewertungen",
        "About": "Über",
        "Testimonials": "Erfahrungsberichte",
        "My other private audio feeds": "Meine anderen privaten Audio-Feeds",
        "Thank you!": "Danke!",
        "You’ve successfully purchased {{productName}} from {{author}}. We sent you an email to {{installEmail}} with access instructions.": "Sie haben erfolgreich {{productName}} von {{author}} gekauft. Wir haben Ihnen eine E-Mail an {{",
        "Point your phone’s camera at the code below to access the private podcast.": "",
        "Purchased item": "Gekaufter Artikel",
        "Access Private Feed Now": "Zugriff auf privaten Feed jetzt",
        "Ohh no, something went wrong with your payment": "Oh nein, beim Bezahlen ist etwas schiefgegangen",
        "Check your credit card details and try one more time.": "Überprüfen Sie Ihre Kreditkartendaten und versuchen Sie es noch einmal.",
        "Try one more time": "Versuchen Sie es noch einmal",
        "Cancel": "Abbrechen",
        "Enter your first name.": "Geben Sie Ihren Vornamen ein.",
        "Enter your last name.": "Geben Sie Ihren Nachnamen ein.",
        "Enter your email address.": "Geben Sie Ihre E-Mail-Adresse ein.",
        "Invalid email address.": "Ungültige E-Mail-Adresse.",
        "Product not found.": "Produkt nicht gefunden.",
        "Payment failed.": "Zahlung fehlgeschlagen.",
        "Fill all the fields.": "Füllen Sie alle Felder aus.",
        "The email address is invalid.": "Die E-Mail-Adresse ist ungültig.",
        "Invalud product ID.": "Ungültige Produkt-ID.",
        "Access denied to product.": "Zugriff auf Produkt verweigert.",
        "Show not available.": "Show nicht verfügbar.",
        "Terms of service": "Nutzungsbedingungen",
        "Privacy policy": "Datenschutzrichtlinie"
    }
};