export default {
    translation: {
        "/ wk": "/ нед",
        "/ mo": "/ мес",
        "/ yr": "/ год",
        "Click for Access": "Нажмите для доступа",
        "Buy Now": "Купить сейчас",
        "Select plan": "Выбрать план",
        "{{episodes}} episodes - free": "{{episodes}} эпизодов - бесплатно",
        "{{episodes}} episodes - {{displayPrice}}": "{{episodes}} эпизодов - {{displayPrice}}",
        "All": "все",
        "Audio Feeds": "Аудио-ленты",
        "Category": "Категория",
        "There are no shows in this category.": "В этой категории нет программ.",
        "The creator doesn’t have any public audio feeds yet.": "Создатель пока не имеет общедоступных аудио-лент.",
        "About me": "Обо мне",
        "My audio feeds": "Мои аудио-ленты",
        "I agree to Hiro’s <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">terms of service</a> & <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">privacy policy</a>.": "Я согласен с условиями службы и политикой конфиденциальности Hiro.",
        "Powered by Hiro.fm": "Поддерживается Hiro.fm",
        "Start your own private audio product": "Запустите свой собственный частный аудиопродукт",
        "episodes": "эпизоды",
        "Free": "Бесплатно",
        "/ week": "/ неделя",
        "/ month": "/ месяц",
        "/ year": "/ год",
        "one-time payment": "единовременный платеж",
        "per week": "в неделю",
        "per month": "в месяц",
        "per year": "в год",
        "Products": "Продукты",
        "Episodes": "Эпизоды",
        "Listeners": "Слушатели",
        "Downloads": "Загрузки",
        "My activity": "Моя активность",
        "Created by:": "Создано:",
        "Last update:": "Последнее обновление:",
        "This audio feed doesn’t have any eposides yet.": "У этой аудио-ленты пока нет эпизодов.",
        "See more": "Подробнее",
        "Please verify the credit card details.": "Пожалуйста, проверьте данные кредитной карты.",
        "Payment": "Оплата",
        "Your details": "Ваши данные",
        "First Name": "Имя",
        "Last Name": "Фамилия",
        "Email": "Электронная почта",
        "Phone (optional)": "Телефон (по желанию)",
        "Invalid phone number.": "Неверный номер телефона.",
        "Payment details": "Детали оплаты",
        "Total payment": "Итоговая сумма",
        "Get Access": "Получить доступ",
        "Pay": "Оплатить",
        "Category:": "Категория:",
        "Get Free Access": "Получить бесплатный доступ",
        "Show currently unavailable": "Показать, что сейчас недоступно",
        "Buy now for {{priceValue}}": "Купить сейчас за {{priceValue}}",
        "Length": "Длительность",
        "reviews": "отзывы",
        "About": "О продукте",
        "Testimonials": "Отзывы",
        "My other private audio feeds": "Мои другие частные аудио-ленты",
        "Thank you!": "Спасибо!",
        "You’ve successfully purchased {{productName}} from {{author}}. We sent you an email to {{installEmail}} with access instructions.": "Вы успешно приобрели {{productName}} от {{author}}. Мы отправили вам письмо на {{installEmail}} с инструкциями по доступу.",
        "Point your phone’s camera at the code below to access the private podcast.": "Наведите камеру своего телефона на код ниже, чтобы получить доступ к частному подкасту.",
        "Purchased item": "Купленный товар",
        "Access Private Feed Now": "Теперь получите доступ к частной ленте",
        "Ohh no, something went wrong with your payment": "Ой, что-то пошло не так с вашей оплатой",
        "Check your credit card details and try one more time.": "Проверьте данные вашей кредитной карты и попробуйте еще раз.",
        "Try one more time": "Попробовать еще раз",
        "Cancel": "Отмена",
        "Enter your first name.": "Введите ваше имя.",
        "Enter your last name.": "Введите вашу фамилию.",
        "Enter your email address.": "Введите ваш адрес электронной почты.",
        "Invalid email address.": "Неверный адрес электронной почты.",
        "Product not found.": "Товар не найден.",
        "Payment failed.": "Платеж не прошел.",
        "Fill all the fields.": "Заполните все поля.",
        "The email address is invalid.": "Адрес электронной почты недействителен.",
        "Invalud product ID.": "Неверный ID продукта.",
        "Access denied to product.": "Доступ к продукту запрещен.",
        "Show not available.": "Показ недоступен.",
        "Terms of service": "Условия использования",
        "Privacy policy": "Политика конфиденциальности"
    }
};