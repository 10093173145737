import _ from "lodash";
import { isValidEmail } from "../../../../_metronic/_helpers";
import { isValidSmsText } from "../../../helpers";
import SendAccess from "./SendAccess";
import SendEmail from "./SendEmail";
import SendPush from "./SendPush";
import SendSMS from "./SendSMS";
import Webhook from "./Webhook";
import TagContact from "./TagContact";
import SendWhatsapp from "./SendWhatsapp";
import React from "react";
import SVG from "react-inlinesvg";

let byType = {
  "send-sms": {
    type: "send-sms",
    title: "Send SMS",
    icon: "/media/def-image/automations/sms-action.svg",
    component: SendSMS,
    getDescription: automation => automation.parameters && automation.parameters.when == "delay"
      ? automation.parameters.delay + " " + (automation.parameters.period || "minutes") + " later"
      : "Immediately",
    validate: values => {
      let errors = {};

      if(values.parameters.when == "delay" && (!values.parameters.delay || isNaN(values.parameters.delay)))
        _.set(errors, "parameters.delay", "Enter the delay.");

      if((!values.parameters.message || !values.parameters.message.trim()) && !values.parameters.attachment)
        _.set(errors, "parameters.message", "Text or attachment is required.");
      //else {
      //  let maxLength = isValidSmsText(values.parameters.message) ? 160 : 70;
      //  if(values.parameters.message.length > maxLength)
      //    _.set(errors, "parameters.message", "The message is too long.");
      //}

      return errors;
    }
  },
  "send-whatsapp": {
    type: "send-whatsapp",
    title: "Send WhatsApp",
    icon: "/media/def-image/automations/whatsapp-action.svg",
    component: SendWhatsapp,
    getDescription: automation => (
      <>
        {automation.parameters && automation.parameters.when == "delay"
          ? automation.parameters.delay + " " + (automation.parameters.period || "minutes") + " later"
          : "Immediately"}

        {automation.parameters.whatsappTemplateStatus == "pending" && <span className="whatsapp-status-badge badge yellow-badge">Approval pending</span>}

        {automation.parameters.whatsappTemplateStatus == "rejected" && <span className="whatsapp-status-badge badge red-badge">Rejected</span>}

        {automation.parameters.whatsappTemplateStatus == "approved" && (
          <div className="whatsapp-status-badge">
            <SVG src="/media/def-image/icons/check.svg" />
            <small>Approved</small>
          </div>
        )}
      </>
    ),
    validate: values => {
      let errors = {};

      if(values.parameters.when == "delay" && (!values.parameters.delay || isNaN(values.parameters.delay)))
        _.set(errors, "parameters.delay", "Enter the delay.");

      if(!values.parameters.message || !values.parameters.message.trim())
        _.set(errors, "parameters.message", "Text is required.");

      return errors;
    }
  },
  "send-email": {
    type: "send-email",
    title: "Send Email",
    icon: "/media/def-image/automations/email-action.svg",
    component: SendEmail,
    getDescription: automation => automation.parameters && automation.parameters.when == "delay"
      ? automation.parameters.delay + " " + (automation.parameters.period || "minutes") + " later"
      : "Immediately",
    validate: values => {
      let errors = {};

      if(values.parameters.when == "delay" && (!values.parameters.delay || isNaN(values.parameters.delay)))
        _.set(errors, "parameters.delay", "Enter the delay.");

      if(!values.parameters.message || !values.parameters.message.trim())
        _.set(errors, "parameters.message", "Enter the message body.");

      if(values.parameters.email && !isValidEmail(values.parameters.email))
        _.set(errors, "parameters.email", "Enter a valid email address.");

      return errors;
    }
  },
  "send-push": {
    type: "send-push",
    title: "Send Mobile App Push Notification",
    icon: "/media/def-image/automations/notification-action.svg",
    component: SendPush,
    getDescription: automation => (automation.parameters && automation.parameters.title) || null,
    validate: values => {
      let errors = {};

      if(!values.parameters.title || !values.parameters.title.trim())
        _.set(errors, "parameters.title", "Enter the title.");
      else if(values.parameters.title.length > 32)
        _.set(errors, "parameters.title", "The title is too long.");

      if(!values.parameters.message || !values.parameters.message.trim())
        _.set(errors, "parameters.message", "Enter the notification text.");
      else if(values.parameters.message.length > 150)
        _.set(errors, "parameters.message", "The text is too long.");

      if(values.parameters.attachment && !values.parameters.attachment.match(/^https:\/\/.+?(\..+)/))
        _.set(errors, "parameters.attachment", "Enter a valid URL (must start with https://).");

      if(values.parameters.actionLabel) {
        if(values.parameters.actionType == "url" && (!values.parameters.action || !values.parameters.action.match(/^https:\/\/.+?(\..+)/)))
          _.set(errors, "parameters.action", "Enter a valid URL (must start with https://).");
        else if(values.parameters.actionType == "app-product" && !values.parameters.action)
          _.set(errors, "parameters.action", "Select a show.");
      }

      return errors;
    }
  },
  "send-access": {
    type: "send-access",
    title: "Send Audio Show",
    icon: "/media/def-image/automations/access-action.svg",
    component: SendAccess,
    getDescription: (automation, { products }) => {
      if(!automation.parameters || !automation.parameters.products || !products)
        return null;

      let names = [];
      for(let id of automation.parameters.products) {
        let product = products.find(p => p._id == id);
        if(product)
          names.push(product.name);
      }

      return names.join(", ");
    },
    validate: values => {
      let errors = {};

      if(!values.parameters.products || !values.parameters.products.length)
        _.set(errors, "parameters.products", "Select at least one show.");

      if(values.parameters.email && !isValidEmail(values.parameters.email))
        _.set(errors, "parameters.email", "Enter a valid email address.");

      return errors;
    }
  },
  "webhook": {
    type: "webhook",
    title: "Create Web Hook",
    icon: "/media/def-image/automations/webhook-action.svg",
    component: Webhook,
    getDescription: automation => (automation.parameters && automation.parameters.url) || null,
    validate: values => {
      let errors = {};

      if(!values.parameters.url || !values.parameters.url.trim())
        _.set(errors, "parameters.url", "Enter the webhook URL.");
      else if(!values.parameters.url.match(/^https?:\/\/[^\?\/]+(\..+)+/))
        _.set(errors, "parameters.url", "Enter a valid URL.");

      if(values.parameters.email && !isValidEmail(values.parameters.email))
        _.set(errors, "parameters.email", "Enter a valid email address.");

      return errors;
    }
  },
  "tag-contact": {
    type: "tag-contact",
    title: "Add Tag",
    icon: "/media/def-image/automations/tag-contact-action.svg",
    component: TagContact,
    // getDescription: automation => automation.parameters && Array.isArray(automation.parameters.tags) && (
    //   <>
    //     {automation.parameters.tags.map(t => <span className="badge gray-badge me-2" key={t}>{t}</span>)}
    //   </>
    // ),
    getDescription: automation => automation.parameters && Array.isArray(automation.parameters.tags) && automation.parameters.tags.join(", "),
    validate: values => {
      let errors = {};

      if(!Array.isArray(values.parameters.tags) || !values.parameters.tags.length)
        _.set(errors, "parameters.tags", "Select the tags.");

      return errors;
    }
  }
};

let list = [
  byType["send-sms"],
  byType["send-whatsapp"],
  byType["send-email"],
  byType["send-push"],
  byType["send-access"],
  byType["webhook"],
  byType["tag-contact"]
];

export default {
  byType,
  list,
  getOptions: () => list.map(o => ({
    id: o.type,
    label: o.title,
    image: o.icon
  }))
};