import { useFetch } from "../../app/helpers";

export default {
  getListeners: idProject => useFetch.get(`product/${idProject}/studentsWithActivationAndBlock`, {}),
  getNewListeners: idProject => useFetch.get(`product/${idProject}/new-listeners`, {}),
  getListenerByEmail: email => useFetch.get(`studentproducts/${email}/`, {}),
  createListener: data => useFetch.post('student/', data),
  editListener: data => useFetch.put('student/', data),
  blockListener: ({ data, productId, listenerId }) => useFetch.post(`product/${productId}/student/${listenerId}/block`, data),
  deleteListener: ({ productId, listenerId }) => useFetch.delete(`product/${productId}/student/${listenerId}`, {}),
  bulkUploadListener: data => useFetch.post(`bulkuploadstudents`, data),
  setListenerAccessEmail: ({ productId, listenerId }) => useFetch.post(`product/${productId}/student/${listenerId}/resend-access-email`, {}),
  updateSubscriptions: (subscriptionsIds, action) => useFetch.post("update-subscriptions", { subscriptionsIds, action }),
  getActivity: (productId, listenerId) => useFetch.get("product/" + productId + "/listener/" + listenerId + "/activity"),
  unlockSampleProduct: data => useFetch.post("unlock-sample-product", data, true, true)
};