export default {
    translation: {
        "/ wk": "/ v",
        "/ mo": "/ mån",
        "/ yr": "/ år",
        "Click for Access": "Klicka för åtkomst",
        "Buy Now": "Köp nu",
        "Select plan": "Välj plan",
        "{{episodes}} episodes - free": "{{episodes}} avsnitt - gratis",
        "{{episodes}} episodes - {{displayPrice}}": "{{episodes}} avsnitt - {{displayPrice}}",
        "All": "Allt",
        "Audio Feeds": "Ljudflöden",
        "Category": "Kategori",
        "There are no shows in this category.": "Det finns inga program i denna kategori.",
        "The creator doesn’t have any public audio feeds yet.": "Skaparen har ännu inga offentliga ljudflöden.",
        "About me": "Om mig",
        "My audio feeds": "Mina ljudflöden",
        "I agree to Hiro’s <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">terms of service</a> & <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">privacy policy</a>.": "Jag godkänner Hiros användarvillkor & sekretesspolicy.",
        "Powered by Hiro.fm": "Drivs av Hiro.fm",
        "Start your own private audio product": "Starta din egen privata ljudprodukt",
        "episodes": "avsnitt",
        "Free": "Gratis",
        "/ week": "/ vecka",
        "/ month": "/ månad",
        "/ year": "/ år",
        "one-time payment": "engångsbetalning",
        "per week": "per vecka",
        "per month": "per månad",
        "per year": "per år",
        "Products": "Produkter",
        "Episodes": "Avsnitt",
        "Listeners": "Lyssnare",
        "Downloads": "Nedladdningar",
        "My activity": "Min aktivitet",
        "Created by:": "Skapad av:",
        "Last update:": "Senast uppdaterad:",
        "This audio feed doesn’t have any eposides yet.": "Detta ljudflöde har ännu inga avsnitt.",
        "See more": "Se mer",
        "Please verify the credit card details.": "Var vänlig verifiera kreditkortsuppgifterna.",
        "Payment": "Betalning",
        "Your details": "Dina uppgifter",
        "First Name": "Förnamn",
        "Last Name": "Efternamn",
        "Email": "E-post",
        "Phone (optional)": "Telefon (valfritt)",
        "Invalid phone number.": "Ogiltigt telefonnummer.",
        "Payment details": "Betalningsdetaljer",
        "Total payment": "Total betalning",
        "Get Access": "Få åtkomst",
        "Pay": "Betala",
        "Category:": "Kategori:",
        "Get Free Access": "Få gratis åtkomst",
        "Show currently unavailable": "Visa för närvarande inte tillgänglig",
        "Buy now for {{priceValue}}": "Köp nu för {{priceValue}}",
        "Length": "Längd",
        "reviews": "recensioner",
        "About": "Om",
        "Testimonials": "Vittnesmål",
        "My other private audio feeds": "Mina andra privata ljudflöden",
        "Thank you!": "Tack!",
        "You’ve successfully purchased {{productName}} from {{author}}. We sent you an email to {{installEmail}} with access instructions.": "Du har framgångsrikt köpt {{productName}} från {{author}}. Vi har skickat ett e-postmeddelande till {{installEmail}} med åtkomstinstruktioner.",
        "Point your phone’s camera at the code below to access the private podcast.": "Rikta telefonens kamera mot koden nedan för att få åtkomst till den privata podcasten.",
        "Purchased item": "Köpt objekt",
        "Access Private Feed Now": "Få åtkomst till privat flöde nu",
        "Ohh no, something went wrong with your payment": "Åh nej, något gick fel med din betalning",
        "Check your credit card details and try one more time.": "Kontrollera dina kreditkortsuppgifter och försök igen.",
        "Try one more time": "Försök igen",
        "Cancel": "Avbryt",
        "Enter your first name.": "Ange ditt förnamn.",
        "Enter your last name.": "Ange ditt efternamn.",
        "Enter your email address.": "Ange din e-postadress.",
        "Invalid email address.": "Ogiltig e-postadress.",
        "Product not found.": "Produkten hittades inte.",
        "Payment failed.": "Betalning misslyckades.",
        "Fill all the fields.": "Fyll i alla fält.",
        "The email address is invalid.": "E-postadressen är ogiltig.",
        "Invalud product ID.": "Ogiltig produkt-ID.",
        "Access denied to product.": "Åtkomst nekad till produkt.",
        "Show not available.": "Programmet är inte tillgängligt.",
        "Terms of service": "Användarvillkor",
        "Privacy policy": "Integritetspolicy"
    }
};